import COMMON from "common";
import services from "services";

const api = {
	get: {
		banks: (params) => services.get(COMMON.ENDPOINT_PATH.BANKS + params, { cancelId: COMMON.ENDPOINT_PATH.BANKS }),
	},
	post: {
		pay: (payload) => services.post(COMMON.ENDPOINT_PATH.PAY, payload),
	},
	patch: {
		cancelPayment: (params) => services.patch(COMMON.ENDPOINT_PATH.CANCEL_PAYMENT + params),
		expirePayment: (params) => services.patch(COMMON.ENDPOINT_PATH.EXPIRE_PAYMENT + params),
	},
	put: {},
};

export default api;
